import React from 'react';
import styled from 'styled-components';
import { Form as Component } from 'antd';
import breakpoint from 'styled-components-breakpoint';
import themes from '../../Themes';
import errorIcon from './error-icon.svg';

const Form = styled(Component)``;

const FormItem = styled(({ select, ...props }) => (
  <Component.Item {...props} />
))`
  .ant-form-item {
    &-label,
    &-label > label {
      font-family: ${themes['font-sans-book']};
      font-size: ${themes['text-4-size']};
      line-height: 18px;
      color: ${themes.I70};
    }

    ${breakpoint('xs', 'sm')`
      .ant-col-xs-0 {
        line-height: 0 !important;
      }
      .ant-form-item-explain {
        margin-bottom: 0 !important;
      }
    `};
  }

  .ant-input {
    font-family: inherit;
  }

  &.ant-form-item {
    margin-bottom: 10px;

    &-has {
      &-success {
        .ant-input {
          padding-right: ${themes['input-padding-horizontal']};
        }

        .ant-input-suffix {
          .ant-form-item-feedback-icon {
            display: none !important;
          }
        }

        .ant-select-auto-complete {
          .ant-select-arrow {
            display: none;
          }
        }
      }

      &-feedback {
        .ant-input-affix-wrapper .ant-input-suffix {
          padding-right: 0 !important;
        }
      }

      &-error {
        .ant-input {
          padding-right: 40px;
          background: ${themes['light-red']};

          &:-webkit-autofill,
          &:hover,
          &:focus,
          &:active {
            -webkit-box-shadow: 0 0 0 30px ${themes['light-red']} inset !important;
            background: ${themes['light-red']} !important;
          }

          &-affix-wrapper .ant-input-suffix {
            padding-right: 18px;
          }

          &-affix-wrapper {
            background: ${themes['light-red']};
            border-color: ${themes['error-color']} !important;

            &:hover {
              border-color: ${themes['error-color']} !important;
            }
          }

          &-textarea-suffix {
            inset-inline-end: 0 !important;

            .ant-form-item-feedback-icon {
              top: 12px;
            }
          }
        }

        .base-select {
          &--error {
            border-color: ${themes['error-color']} !important;
            background: ${themes['light-red']};

            option {
              background: white !important;
            }
          }

          &__icon {
            display: none;
          }

          &:before {
            width: 16px;
            height: 16px;
            display: block;
            content: '';
            background-image: url('${errorIcon}');
            background-size: cover;
            position: absolute;
            right: 16px;
            top: 12px;
          }
        }

        .ant-form-item-feedback-icon-error {
          position: absolute;
          width: 16px;
          height: 16px;
          display: block;
          right: ${(props) => (props.select ? '20px' : '16px')};
          margin-top: auto;

          .anticon-close-circle {
            display: none !important;
          }

          &:before {
            width: 100%;
            height: 100%;
            display: block;
            content: '';
            background-image: url('${errorIcon}');
            background-size: cover;
          }
        }

        .ant-select-auto-complete {
          .ant-select-arrow {
            display: none;
          }
        }
      }
    }

    &-with-help {
      .ant-form-item-explain {
        margin-top: 4px;
        margin-bottom: ${themes['form-item-margin-bottom']};
        font-family: ${themes['font-sans-book']};
        line-height: ${themes['form-item-error-message-line-height']};

        &-error {
          color: ${themes['error-color']} !important;
        }
      }
    }

    &.nowrap {
      .ant-form-item-label {
        white-space: nowrap;
      }
    }
  }
`;

Form.defaultProps = {
  layout: 'vertical',
};

FormItem.defaultProps = {
  labelSize: 'default',
};
Form.Item = FormItem;

/** @component */
export default Form;
